import { useLayoutEffect } from '@loveholidays/design-system';
// eslint-disable-next-line import/no-extraneous-dependencies
import { History, Location } from 'history';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useClient } from 'urql';

import { useAppContext } from '@Contexts/contexts';
import { useRouteContext } from '@Contexts/RouteContext';
import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { useQueryParams } from '@Core/helpers/url';
import getGenericPage from '@Pages/generic/getGenericPage.gql';
import { getSearchSelectionFromUrl } from '@Stores/SearchSelectionStore/getSearchSelectionFromUrl';

interface LocationState {
  sunrise?: boolean;
}

/**
 * Adds `sunrise=true` to the location state if it's not there already.
 */
const patchLocationState = (location: Location<LocationState>, replace: History['replace']) => {
  if (!location.state?.sunrise) {
    replace({
      ...location,
      state: {
        ...location.state,
        sunrise: true,
      },
    });
  }
};

/**
 * Adds `sunrise=true` to the navigation states, so when navigating back we can check for
 * `sunrise=true` in the previous state to decide whether to do a full page reload or
 * a client side navigation.
 */
export const NavigationInterceptor: React.FC = () => {
  const { listen, location, replace } = useHistory<LocationState>();
  const { isWebView } = useAppContext();
  const client = useClient();
  const { pageType } = useRouteContext();
  const { navigationStack } = useUserPreferences();
  const { q: searchTerm } = useQueryParams();

  // Reset stack on mount
  useEffect(() => {
    // Patch current location state
    patchLocationState(location, replace);

    // Create new stack on fresh page load
    navigationStack.set([
      { pageType, path: location.pathname, search: location.search, searchTerm },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Correctly set page type on change transition
  // Since history interception occurs before page transition
  // and therefore the latest page type is not available at that point
  useEffect(() => {
    const stack = navigationStack.get();
    const top = stack[stack.length - 1];

    // only reset top page type if its undefined
    if (top && !top.pageType) {
      stack.pop();
      navigationStack.set([
        ...stack,
        {
          ...top,
          pageType,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  useEffect(() => {
    return listen((location: Location<LocationState>, action: History['action']) => {
      const { state = {} } = location;
      const stack = navigationStack.get();

      switch (action) {
        case 'PUSH': {
          // add new location to top of the stack
          navigationStack.set([
            ...stack,
            {
              path: location.pathname,
              search: decodeURIComponent(location.search),
              searchTerm,
              pageType: undefined,
            },
          ]);
          // fallthrough to replace is expected -
          // since we want to do a `replace` on push
          // to fix the top of the location stack with
          // some extra meta data
        }
        case 'REPLACE': {
          // Patch location state when navigation forward - PUSH and REPLACE
          patchLocationState(location, replace);
          break;
        }
        // On backward navigation - on POP
        // do a page reload if the previous page was not a Sunrise page
        // or if the previous page is generic and not cached by urql
        case 'POP': {
          // remove top element of stack
          stack.pop();
          navigationStack.set(stack);
          // now top of the stack is the target
          const target = stack[stack.length - 1];

          if (!target || !state.sunrise) {
            // perform SSR RELOAD - target not present in stack or is sunrise
            window.location.reload();

            return;
          }

          if (target.pageType === 'generic') {
            // look in urql cache for a matching generic page query
            const genericPageIsCached = client.readQuery(getGenericPage, {
              path: target.path,
              searchSelection: getSearchSelectionFromUrl(target.search),
              autogenerated: !!target.searchTerm?.includes('autogenerated=true'),
              searchTerm: target.searchTerm,
            });

            if (!genericPageIsCached) {
              // perform SSR RELOAD - target generic page not cached
              window.location.reload();

              return;
            }

            // allow CSR NAVIGATION - target generic page is cached
          }

          // allow CSR NAVIGATION - target page is not generic
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (isWebView) {
      return listen((location) => {
        if (window.sendFrontierEvent) {
          window.sendFrontierEvent({ name: 'sunriseLocationChange', message: location.pathname });
        }
      });
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
